import { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

import Button from "../Button";

interface ListMultiSelectProps {
  maintainOrder?: boolean;
  selectedItems?: string[];
  defaultItems?: string[];
  items?: string[];
  onButtonClick: (item: string) => void;
  direction?: "row" | "column";
}

const ListMultiSelect: React.FC<ListMultiSelectProps> = ({
  direction = "row",
  selectedItems,
  defaultItems,
  onButtonClick,
  maintainOrder = false
}) => {
  const [items, setItems] = useState<string[]>(selectedItems || defaultItems || []);

  useEffect(() => {
    if (maintainOrder) {
      setItems(defaultItems || []);
    } else {
      const remainingItems = defaultItems?.filter((item) => !selectedItems?.includes(item)) || [];
      setItems([...(selectedItems || []), ...remainingItems]);
    }
  }, [selectedItems, defaultItems, maintainOrder]);

  const handleButtonClick = (item: string) => {
    if (items?.includes(item)) {
      setItems((prevItems) => prevItems.filter((prevItem) => prevItem !== item));
    } else {
      setItems((prevItems) => [...prevItems, item]);
    }
    onButtonClick(item);
  };

  return (
    <Stack direction={direction} spacing={3} w="full" minH="48px" h="fit-content" flexWrap="wrap" ml={1} mr={1}>
      {items?.map((name, index) => (
        <Button
          key={index}
          colorScheme={selectedItems?.includes(name) ? "blackAlpha" : "gray"}
          onClick={() => handleButtonClick(name)}
          order={maintainOrder ? 0 : selectedItems?.includes(name) ? -1 : 0}
          iconType={selectedItems?.includes(name) ? "Remove" : "Add"}
          color={selectedItems?.includes(name) ? "darkslategray" : "gray"}
          whiteSpace="normal"
          height="auto"
          minHeight="40px"
          fontSize="sm"
          px={2}
          sx={{
            wordBreak: "break-word",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            maxWidth: "100%",
            "& > *": {
              maxHeight: "2.4em",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis"
            }
          }}
        >
          {name}
        </Button>
      ))}
    </Stack>
  );
};

export default ListMultiSelect;
