import { useCallback } from "react";

import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { usePageStatus } from "./usePageStatus";
import { Question, QuestionType, UserSopAnswer, AnswerOptions } from "../../../../shared/models";
import { queryClient } from "../App";
import { upsertReportAnswers, upsertSopAnswer } from "../services/api-service";
import { Session, useAppStore } from "../store";
import { createAnswerStructure } from "../utils/answerFormatters";

export const useSopAnswerMutation = () => {
  const { session, setSession } = useAppStore();
  const { updatePageStatus } = usePageStatus();
  const toast = useToast();

  const handleAnswerChange = useCallback(
    (questionId: string, answer: string | boolean | object, question: Question) => {
      let newAnswer: string | boolean | string[] | object;

      if (
        question.type === QuestionType.MultiselectWithOther ||
        question.type === QuestionType.Multiselect ||
        question.type === QuestionType.MultiselectPreselectedFromDependencies
      ) {
        const currentAnswer = session?.[questionId]?.answer;
        if (Array.isArray(currentAnswer)) {
          newAnswer = currentAnswer.includes(answer as string)
            ? currentAnswer?.filter((item) => item !== answer)
            : [...currentAnswer, answer as string];
        } else if (!currentAnswer && Array.isArray(answer)) {
          newAnswer = answer;
        } else {
          newAnswer = [answer as string];
        }
      } else if (question.type === QuestionType.BasicInfo) {
        newAnswer = answer as object;
      } else {
        newAnswer = answer as string | boolean;
      }

      setSession({
        ...(session ?? {}),
        [questionId]: {
          ...session?.[questionId],
          type: question.type,
          answer: newAnswer,
          reportAnswer: session?.[questionId]?.reportAnswer
        }
      });

      mutation.mutate({
        questionId,
        answer: newAnswer as Omit<UserSopAnswer, "reportAnswer">,
        question,
        previousSession: session
      });

      updatePageStatus({
        questionId,
        answer: newAnswer as Omit<AnswerOptions, "reportAnswer">
      });
    },
    [session, setSession, updatePageStatus]
  );

  const mutation = useMutation({
    mutationFn: ({
      questionId,
      answer,
      question
    }: Omit<UserSopAnswer, "reportAnswer"> & {
      question: Question;
      previousSession: Session;
    }) => {
      const reportAnswer = createAnswerStructure(question, answer);
      return upsertSopAnswer({ questionId, answer, reportAnswer });
    },
    // onSuccess: () => {
    //   queryClient.invalidateQueries({ queryKey: ["sopAnswers"] });
    // },
    onError: (error, variables) => {
      // Revert to previous session state on error
      setSession(variables.previousSession);
      toast({
        title: "Error",
        description: "Error saving SOP answer. Please try again.",
        status: "error",
        position: "top",
        duration: 5000
      });
      console.error("Error upserting SOP answer:", error);
    }
  });

  return { handleAnswerChange, mutation };
};

export const useReportAnswersMutation = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: (reportAnswersToAdd: { questionId: string; reportAnswer: string | object }[]) =>
      upsertReportAnswers(reportAnswersToAdd),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sopAnswers"] });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Error saving SOP report answers. Please try again.",
        status: "error",
        position: "top",
        duration: 5000
      });
      console.error("Error upserting SOP report answers:", error);
    }
  });
};
