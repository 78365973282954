import { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

import ListMultiSelect from "./ListMultiSelect";
import TextInput from "../TextInput";

interface OtherMultiSelectProps {
  selectedItems?: string[];
  defaultItems?: string[] | Record<string, string[]>[];
  onButtonClick: (item: string) => void;
  direction?: "row" | "column";
  onAddClick?: (value: string) => void;
}

const OtherMultiSelect: React.FC<OtherMultiSelectProps> = ({
  selectedItems,
  defaultItems,
  onButtonClick,
  onAddClick
}) => {
  const processDefaultItems = (items: string[] | Record<string, string[]>[] | undefined): string[] => {
    if (!items) return [];

    if (Array.isArray(items) && items.length > 0) {
      const processedItems = items.filter(item => typeof item !== 'boolean');

      if (typeof processedItems[0] === 'string') {
        return processedItems.filter(item => typeof item === 'string') as string[];
      } else {
        // Handle array of objects
        const allValues = items.flatMap(obj =>
          Object.values(obj)
            .flat()
            .filter(value => typeof value === 'string')
        );
        // Remove duplicates
        return [...new Set(allValues)];
      }
    }
    return [];
  };

  const [unselectedItems, setUnselectedItems] = useState<string[]>(() => {
    const processedItems = processDefaultItems(defaultItems);
    return processedItems.filter(item => !selectedItems?.includes(item));
  });

  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const processedItems = processDefaultItems(defaultItems);
    const remainingItems = processedItems.filter(item => !selectedItems?.includes(item));
    setUnselectedItems(remainingItems);
  }, [selectedItems, defaultItems]);

  const handleButtonClick = (item: string) => {
    onButtonClick(item);
  };

  const handleAddClick = (value: string) => {
    if (onAddClick) {
      onAddClick(value);
      setValue("");
    }
  };

  return (
    <Stack direction="column" spacing={3} w="full" h="fit-content" flexWrap="wrap">
      <ListMultiSelect
        selectedItems={selectedItems || []}
        onButtonClick={handleButtonClick}
      />

      <TextInput
        showAdd={true}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        onAddClick={handleAddClick}
        mb={2}
      />

      <ListMultiSelect
        defaultItems={unselectedItems}
        onButtonClick={handleButtonClick}
      />
    </Stack>
  );
};

export default OtherMultiSelect;
