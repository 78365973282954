import { useCallback, useEffect, useState } from "react";

import { VStack, Text, HStack, Icon, useToast, Collapse, Spinner } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";

import DependencyList from "./DependencyList";
import FileList from "./FileList";
import UploadZone from "./UploadZone";
import { Question } from "../../../../../../../shared/models";
import { usePdfUpload } from '../../../../hooks/usePdfUpload';
import { Session } from "../../../../store";
import OtherMultiSelect from "../../../MultiSelect/OtherMultiSelect";

export interface UploadedFile {
  file: File;
  status: 'pending' | 'complete' | 'error';
  errorMessage?: string;
}

interface FileUploadProps {
  question: Question;
  dependencyAnswers: string[];
  onAnswerChange: (questionId: string, answer: string[]) => void;
  session: Session;
}

const FileUpload: React.FC<FileUploadProps> = ({ question, dependencyAnswers, onAnswerChange, session }) => {
  const { id } = question;
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    (session?.[id]?.answer as string[]) || []
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const toast = useToast();
  const {
    uploadAndProcessPdf,
    extractedItems,
    isUploading,
  } = usePdfUpload();

  useEffect(() => {
    setSelectedItems((session?.[id]?.answer as string[]) || []);
  }, [session]);

  const updateFileStatus = (fileName: string, status: 'pending' | 'complete' | 'error', errorMessage?: string) => {
    setUploadedFiles(prev =>
      prev.map(f =>
        f.file.name === fileName
          ? { ...f, status, ...(errorMessage ? { errorMessage } : {}) }
          : f
      )
    );
  };

  const isDuplicateFile = (newFile: File, existingFile: UploadedFile) =>
    existingFile.file.name === newFile.name && existingFile.file.size === newFile.size;

  const handleDuplicates = (files: File[]) => {
    const duplicates = files.filter(file =>
      uploadedFiles.some(existing => isDuplicateFile(file, existing))
    );

    if (duplicates.length > 0) {
      toast({
        title: "Duplicate file(s) detected",
        description: `${duplicates.map(f => f.name).join(", ")} already uploaded`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    }

    return files.filter(file =>
      !uploadedFiles.some(existing => isDuplicateFile(file, existing))
    );
  };

  const processFile = async (file: UploadedFile) => {
    updateFileStatus(file.file.name, 'pending');

    try {
      await uploadAndProcessPdf(file.file);
      updateFileStatus(file.file.name, 'complete');
    } catch (error) {
      updateFileStatus(file.file.name, 'error', 'Failed to process PDF');
      toast({
        title: "Error processing PDF",
        description: "Failed to extract text from PDF",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    }
  };

  const onFileSelect = useCallback(async (files: File[]) => {
    setIsExpanded(false);

    const uniqueFiles = handleDuplicates(files);
    const newFiles = uniqueFiles.map(file => ({
      file,
      status: 'pending' as const
    }));

    setUploadedFiles(prev => [...prev, ...newFiles]);

    await Promise.all(newFiles.map(processFile));
  }, [uploadAndProcessPdf, uploadedFiles, toast]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileSelect(acceptedFiles);
  }, [onFileSelect, uploadedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDropRejected: () => {
      toast({
        title: "Invalid file type",
        description: "Only PDF files are allowed",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    }
  });

  const handleButtonClick = (item: string) => {
    let newItems = [...selectedItems];

    if (newItems.includes(item)) {
      newItems = newItems.filter(i => i !== item);
    } else {
      newItems.push(item);
    }

    setSelectedItems(newItems);
    onAnswerChange(id, newItems);
  };

  const handleAddClick = (value: string) => {
    setSelectedItems(prev => [...prev, value]);
    onAnswerChange(id, [...selectedItems, value]);
  };

  return (
    <VStack w="full" spacing={4} mt={4}>
      <Text color="brand.500" textAlign="left" fontWeight="bold" fontSize="lg" alignSelf="flex-start">
        {question.footnote || "Don't have a pdf to upload? You can manually add items by entering them below."}
      </Text>

      {(extractedItems.length > 0 || isUploading) && (
        <HStack
          w="full"
          justify="space-between"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          border="1px solid"
          borderColor="gray.200"
          p={2}
          borderRadius="lg"
        >
          <HStack spacing={2}>
            <Icon
              as={isExpanded ? FiChevronDown : FiChevronRight}
              fontSize="xl"
              color="gray.500"
              transition="transform 0.2s"
            />
            <Text color="gray.700">
              {isUploading ? "Extracting items from uploaded file..." : `${uploadedFiles.length} file${uploadedFiles.length > 1 ? 's' : ''} uploaded`}
            </Text>
          </HStack>
          {isUploading && <Spinner mr={2} />}
        </HStack>
      )}

      <Collapse in={isExpanded} style={{ width: '100%' }}>
        <UploadZone
          uploadedFiles={uploadedFiles.map(uf => uf.file)}
          isDragActive={isDragActive}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
        />

        {uploadedFiles.length > 0 && <FileList uploadedFiles={uploadedFiles} />}
      </Collapse>

      {dependencyAnswers && dependencyAnswers.length > 0 && (
        <DependencyList dependencyAnswers={dependencyAnswers} />
      )}

      <Text color="gray.500" textAlign="center" fontStyle="italic" fontSize="sm" alignSelf="flex-start">
        Items from uploaded files will be added below, or you can add new items manually.
      </Text>

      <VStack w="full" spacing={4} alignItems="flex-start" mt={4}>
        <OtherMultiSelect
          selectedItems={selectedItems}
          defaultItems={extractedItems}
          onButtonClick={handleButtonClick}
          onAddClick={handleAddClick}
        />
      </VStack>
    </VStack>
  );
};

export default FileUpload;