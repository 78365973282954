export interface UserInfo {
  id: number;
  authId: string;
  emailAddress?: string;
  isAdmin: boolean;
  fetchedAt: Date;
}

export enum QuestionType {
  ConfirmContext,
  MultiselectWithOther,
  Multiselect,
  MultiselectPreselectedFromDependencies,
  SingleSelect,
  YesNo,
  Textarea,
  // for table select, last question dependency is is X axis (all other dependencies are Y axis)
  TableSelect,

  // Custom Question Types
  BasicInfo,
  Custom,
  AccordionMultiSelect,
  AccordionMultiSelectDepsAsOptions,
  FileUpload
}

export type Answer = string | Array<string> | boolean | object;

export interface AnswerOptions {
  answer: Answer;
  reportAnswer: string | null | object;
}
export interface UserSopAnswerToSave {
  id: string;
  userId: string;
  questionId: string;
  answer: AnswerOptions;
  updatedAt: Date;
}

export interface UserSopAnswer extends AnswerOptions {
  questionId: string;
}

export enum QuestionDependencyType {
  Required = "required",
  Silent = "silent",
  NotRequired = "not-required"
}

export interface QuestionDependency {
  questionId: string;
  label?: string;
  type: QuestionDependencyType;
}

export interface Question {
  id: string;
  reportWording: string;
  type: QuestionType;
  intro?: string;
  inputMask?: "text" | "number";
  prompt: string;
  options?: Array<string>;
  required?: boolean;
  footnote?: string;
  excludeFromReport?: boolean;
  contentFile?: string;
  dependencies?: Array<QuestionDependency>;
}

export interface Page {
  id: string;
  urlSlug: string;
  name: string;
  header: string;
  dependencies?: Array<QuestionDependency>;
  questions: Array<Question>;
}

export interface Section {
  id: string;
  name: string;
  urlSlug: string;
  pages: Array<Page>;
  dependencies?: Array<QuestionDependency>;
}

export interface SOPGuide {
  sections: Array<Section>;
}

export interface UnAuthorizedResponse {
  isAuthorized: boolean;
  message: string;
  redirectToSignIn: boolean;
  redirectToAccessDenied: boolean;
}
