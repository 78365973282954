import { VStack, Text, Box, Wrap, WrapItem, Badge } from "@chakra-ui/react";

interface DependencyListProps {
  dependencyAnswers: string[];
}

const DependencyList: React.FC<DependencyListProps> = ({ dependencyAnswers }) => (
  <VStack w="full" spacing={2} alignItems="flex-start">
    <Text color="gray.500" textAlign="center">
      Current Equipment List (from previous question responses):
    </Text>

    <Box w="full">
      <Wrap spacing={2}>
        {Object.values(dependencyAnswers).filter((dep) => dep !== "" && typeof dep !== "boolean").map((dependencyItem: string) => (
          <WrapItem key={dependencyItem}>
            <Badge colorScheme="brand" px={2} py={1} borderRadius="md">
              {dependencyItem}
            </Badge>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  </VStack>
);

export default DependencyList;