"use client";

import { ReactNode, useEffect } from "react";

import { Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import Body from "./Body";
import Footer from "./Footer";
import SideBar from "./SideBar";

type LayoutProps = {
  bodyPadding?: number[];
  bgColor?: string;
  children: ReactNode;
};

export default function Layout({ children, bodyPadding, bgColor }: LayoutProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const location = useLocation();
  const bodyPaddingToUse = bodyPadding === undefined ? [4, 6] : bodyPadding;

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <Grid
      className="id--layout"
      templateAreas={`"nav main"
                    "nav footer"`}
      gridTemplateRows={`1fr auto`}
      gridTemplateColumns={`auto 1fr`}
      height="100vh"
    >
      <GridItem area="main" as="main">
        <Body children={children} bodyPadding={bodyPaddingToUse} bgColor={bgColor} />
      </GridItem>
      <GridItem area="nav" as="aside">
        <SideBar menuIsVisible={isOpen} closeMenu={() => onClose()} />
      </GridItem>
      <GridItem area="footer" as="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
}
