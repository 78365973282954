import { useState, useEffect } from "react";

import {
  VStack,
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  HStack,
  Icon,
  Tooltip
} from "@chakra-ui/react";
import { Question } from "@shared/models";
import { FiAlertTriangle } from "react-icons/fi";

import { Session } from "../../../store";
import OtherMultiSelect from "../../MultiSelect/OtherMultiSelect";

interface AccordionMultiSelectProps {
  question: Question;
  session: Session;
  onAnswerChange: (questionId: string, answer: object) => void;
  useDependenciesAsOptions?: boolean;
}

interface AccordionAnswer {
  [key: string]: string[];
}

const AccordionMultiSelect: React.FC<AccordionMultiSelectProps> = ({
  question,
  session,
  onAnswerChange,
  useDependenciesAsOptions = true
}) => {
  const { id, dependencies, options } = question;
  const [localAnswers, setLocalAnswers] = useState<AccordionAnswer>((session[id]?.answer || {}) as AccordionAnswer);

  // Get all options from any dependency's answers
  const dependencyAnswers =
    dependencies?.flatMap((dep) => (dep.questionId ? (session[dep.questionId]?.answer as string[]) || [] : [])) || [];

  // Determine which set to use as accordion options vs multiselect options
  const accordionOptions = useDependenciesAsOptions ? dependencyAnswers : options || [];
  const multiselectOptions = useDependenciesAsOptions ? options || [] : dependencyAnswers;

  useEffect(() => {
    const sessionAnswer = (session[id]?.answer || {}) as AccordionAnswer;
    const isAnswerDifferent = JSON.stringify(sessionAnswer) !== JSON.stringify(localAnswers);

    if (isAnswerDifferent) {
      setLocalAnswers(sessionAnswer);
    }
  }, [session, id]);

  useEffect(() => {
    const hasAllAnswers = accordionOptions.every((option) => localAnswers[option]?.length > 0);
    const sessionAnswer = (session[id]?.answer || {}) as AccordionAnswer;
    const isAnswerDifferent = JSON.stringify(sessionAnswer) !== JSON.stringify(localAnswers);

    if (hasAllAnswers && isAnswerDifferent) {
      onAnswerChange(id, localAnswers);
    }
  }, [localAnswers, accordionOptions, id, onAnswerChange, session]);

  const handleAnswerChange = (option: string, value: string, isAdd: boolean) => {
    const currentOption = localAnswers[option] || [];
    let newOptionAnswers: string[];

    if (isAdd) {
      newOptionAnswers = [...currentOption, value];
    } else {
      newOptionAnswers = currentOption.includes(value)
        ? currentOption.filter((item: string) => item !== value)
        : [...currentOption, value];
    }

    setLocalAnswers((prev) => ({
      ...prev,
      [option]: newOptionAnswers
    }));
  };

  return (
    <VStack w="full" spacing={0}>
      {accordionOptions.some((option) => !localAnswers[option]?.length) && (
        <Text color="orange.400" mb={4} alignSelf="flex-start">
          {accordionOptions.filter((option) => !localAnswers[option]?.length).length} option(s) remaining to be answered
        </Text>
      )}
      <Accordion allowMultiple={false} allowToggle w="full">
        {accordionOptions.map((option, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <HStack spacing={2} alignItems={"flex-start"}>
                  <Box w={5} display="inline-block">
                    {!localAnswers[option]?.length && (
                      <Tooltip
                        label="Select an option that applies, enter another option or N/A."
                        hasArrow
                        placement="top"
                      >
                        <span>
                          <Icon as={FiAlertTriangle} color="orange.400" boxSize={5} />
                        </span>
                      </Tooltip>
                    )}
                  </Box>

                  <Text fontWeight="bold" fontSize={"lg"}>
                    {`${option}: `}
                  </Text>
                  <Text fontStyle={"italic"} fontSize={"sm"} pt={1} maxW={"85%"}>
                    {localAnswers[option]?.length ? localAnswers[option].join(", ") : ""}
                  </Text>
                </HStack>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={12}>
              <VStack alignItems="flex-start" justifyContent="flex-start" w="full" gap={4}>
                <Text fontSize={"md"} fontStyle={"italic"} color={"gray.500"}>
                  Select all that apply for {option}.
                </Text>
                <OtherMultiSelect
                  selectedItems={localAnswers[option] || []}
                  defaultItems={[...multiselectOptions, "N/A"]}
                  onButtonClick={(value: string) => handleAnswerChange(option, value, false)}
                  onAddClick={(value: string) => handleAnswerChange(option, value, true)}
                />
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
  );
};

export default AccordionMultiSelect;
