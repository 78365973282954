import { List, ListItem, HStack, Icon, Text, Badge } from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";

import { UploadedFile } from "./index";

interface FileListProps {
  uploadedFiles: UploadedFile[];
}

const FileList: React.FC<FileListProps> = ({ uploadedFiles }) => (
  <List spacing={1} w="full" mt={4} mb={8}>
    {uploadedFiles.map(({ file, status }) => (
      <ListItem
        key={`${file.name}-${file.size}`}
        px={4}
        py={2}
        bg="gray.50"
        borderRadius="lg"
      >
        <HStack justify="space-between" w="full">
          <HStack spacing={4}>
            <Icon as={FiFile} color="brand.500" />
            <Text fontWeight="medium">{file.name}</Text>
          </HStack>

          <HStack spacing={4}>
            <Badge
              colorScheme={
                status === 'complete'
                  ? 'green'
                  : status === 'error'
                    ? 'red'
                    : 'yellow'
              }
              px={4}
              py={1}
              borderRadius="lg"
            >
              {status}
            </Badge>
          </HStack>
        </HStack>
      </ListItem>
    ))}
  </List>
);

export default FileList;