import React, { useEffect } from "react";

import { VStack, HStack, FormControl, FormLabel, InputGroup, FormErrorMessage } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";

import TextInput from "../../TextInput";

interface BasicInfoFormProps {
  onBlur: (value: object) => void;
  previousData: object;
}

export interface BasicInfoFormData {
  establishmentName?: string;
  licenseHolderFirstName?: string;
  licenseHolderLastName?: string;
  address?: string;
  email?: string;
  phoneNumber?: string;
}

const BasicInfoForm: React.FC<BasicInfoFormProps> = ({ previousData, onBlur }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors
  } = useForm<BasicInfoFormData>({
    defaultValues: previousData
  });

  useEffect(() => {
    if (previousData) {
      (Object.keys(previousData) as (keyof BasicInfoFormData)[]).forEach((key) => {
        setValue(key, (previousData as BasicInfoFormData)[key]);
      });
    }
  }, [previousData, setValue, previousData]);

  const handleFieldBlur = (fieldName: keyof BasicInfoFormData) => {
    const fieldValue = getValues(fieldName);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isRequired = (control._fields[fieldName]?._f as any)?.required;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pattern = (control._fields[fieldName]?._f as any)?.pattern;

    if (!!isRequired && !fieldValue) {
      return setError(fieldName, { type: "required", message: "This field is required" });
    } else if (fieldValue && pattern && !(pattern as { value: RegExp }).value.test(fieldValue)) {
      return setError(fieldName, { type: "pattern", message: (pattern as { message: string }).message });
    } else {
      clearErrors(fieldName);
    }

    const updatedData = { ...getValues(), [fieldName]: fieldValue };
    onBlur(updatedData);
  };

  const onSubmit = (data: BasicInfoFormData) => {
    onBlur(data);
  };

  return (
    <VStack mt={2} width="100%" maxWidth={"3xl"} alignSelf={"center"}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <VStack spacing={4}>
          <FormControl isInvalid={!!errors.establishmentName} isRequired>
            <FormLabel fontWeight="bold" fontSize="md">
              Name of Establishment
            </FormLabel>
            <Controller
              name="establishmentName"
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  fontSize="sm"
                  value={field.value ?? ""}
                  placeholder="Enter name of establishment"
                  onBlur={() => handleFieldBlur("establishmentName")}
                />
              )}
            />
            {errors.establishmentName && <FormErrorMessage>{errors.establishmentName?.message}</FormErrorMessage>}
          </FormControl>

          <HStack spacing={8} width="100%" alignItems="start">
            <FormControl isInvalid={!!errors.licenseHolderFirstName} isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                License Holder First Name
              </FormLabel>
              <Controller
                name="licenseHolderFirstName"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    fontSize="sm"
                    value={field.value ?? ""}
                    placeholder="Enter first name"
                    onBlur={() => handleFieldBlur("licenseHolderFirstName")}
                  />
                )}
              />
              {errors.licenseHolderFirstName && (
                <FormErrorMessage>{errors.licenseHolderFirstName?.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errors.licenseHolderLastName} isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                License Holder Last Name
              </FormLabel>
              <Controller
                name="licenseHolderLastName"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    fontSize="sm"
                    value={field.value ?? ""}
                    placeholder="Enter last name"
                    onBlur={() => handleFieldBlur("licenseHolderLastName")}
                  />
                )}
              />
              {errors.licenseHolderLastName && (
                <FormErrorMessage>{errors.licenseHolderLastName?.message}</FormErrorMessage>
              )}
            </FormControl>
          </HStack>

          <FormControl isInvalid={!!errors.address} isRequired>
            <FormLabel fontWeight="bold" fontSize="sm">
              Establishment Address
            </FormLabel>
            <Controller
              name="address"
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  fontSize="sm"
                  value={field.value ?? ""}
                  placeholder="Enter address"
                  onBlur={() => handleFieldBlur("address")}
                />
              )}
            />
            {errors.address && <FormErrorMessage>{errors.address?.message}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel fontWeight="bold" fontSize="sm">
              Establishment Email
            </FormLabel>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "This field is required"
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  fontSize="sm"
                  value={field.value ?? ""}
                  placeholder="Enter email"
                  onBlur={() => handleFieldBlur("email")}
                />
              )}
            />
            {errors.email && <FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={!!errors.phoneNumber} isRequired>
            <FormLabel fontWeight="bold" fontSize="sm">
              Establishment Phone Number
            </FormLabel>
            <InputGroup>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: "This field is required"
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    fontSize="sm"
                    value={field.value ?? ""}
                    placeholder="Enter phone number (ex: 123-456-7890)"
                    onBlur={() => handleFieldBlur("phoneNumber")}
                  />
                )}
              />
            </InputGroup>
            {errors.phoneNumber && <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>}
          </FormControl>

        </VStack>
      </form>
    </VStack>
  );
};

export default BasicInfoForm;
