import { Box, Stack, Icon, Text, Button } from "@chakra-ui/react";
import { DropzoneRootProps, DropzoneInputProps } from "react-dropzone";
import { FiUpload } from "react-icons/fi";

interface UploadZoneProps {
  uploadedFiles: File[];
  isDragActive: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}

const UploadZone: React.FC<UploadZoneProps> = ({
  uploadedFiles,
  isDragActive,
  getRootProps,
  getInputProps
}) => (
  <Box
    {...getRootProps()}
    w="full"
    p={uploadedFiles.length > 0 ? 4 : 16}
    border="2px dashed"
    borderColor={isDragActive ? "brand.500" : "gray.300"}
    borderRadius="lg"
    bg={isDragActive ? "brand.50" : "transparent"}
    cursor="pointer"
    transition="all 0.2s"
    _hover={{
      borderColor: "brand.500",
      bg: "brand.50"
    }}
  >
    <input {...getInputProps()} />
    <Stack spacing={4} direction={uploadedFiles.length > 0 ? "row" : "column"} alignItems="center" justifyContent="center">
      <Icon as={FiUpload} fontSize="2xl" color={isDragActive ? "brand.500" : "gray.500"} />
      <Text color={isDragActive ? "brand.500" : "gray.500"} textAlign="center">
        {isDragActive ? "Drop files here" : "Drag and drop PDF files here"}
      </Text>
      <Button size="sm" colorScheme="brand" variant="outline">
        or click to select files
      </Button>
    </Stack>
  </Box>
);

export default UploadZone;