import { VStack, Text } from "@chakra-ui/react";
import { QuestionType, Question } from "@shared/models";

import AccordionMultiSelect from "./AccordionMultiSelect";
import BasicInfoForm, { BasicInfoFormData } from "./BasicInfo";
import FileUpload from "./FileUpload";
import { Session } from "../../../store";

interface DynamicQuestionsProps {
  question: Question;
  handleNextClick: () => void;
  session: Session;
  onAnswerChange: (questionId: string, answer: string | boolean | object) => void;
  dependencyAnswers: string[];
}

const DynamicQuestion: React.FC<DynamicQuestionsProps> = ({ question, session, onAnswerChange, dependencyAnswers }) => {
  const { id, type } = question;

  return (
    <VStack key={id} alignItems="flex-start" justifyContent="flex-start" w="full" gap={4}>
      {type === QuestionType.Custom && <Text>UNDER CONSTRUCTION. SOMETHING COOL SHOULD BE HERE SOON.</Text>}

      {type === QuestionType.FileUpload && (
        <FileUpload
          question={question}
          dependencyAnswers={dependencyAnswers}
          onAnswerChange={onAnswerChange}
          session={session}
        />
      )}

      {(type === QuestionType.AccordionMultiSelect || type === QuestionType.AccordionMultiSelectDepsAsOptions) && (
        <AccordionMultiSelect
          question={question}
          session={session}
          onAnswerChange={onAnswerChange}
          useDependenciesAsOptions={type === QuestionType.AccordionMultiSelectDepsAsOptions}
        />
      )}

      {type === QuestionType.BasicInfo && (
        <BasicInfoForm
          previousData={session[id]?.answer as BasicInfoFormData}
          onBlur={(value: BasicInfoFormData) => onAnswerChange(id, value)}
        />
      )}
    </VStack>
  );
};

export default DynamicQuestion;
