import { useEffect, useState } from "react";

import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import ReactMarkdown, { Components } from "react-markdown";

import Button from "../Button";

interface ContextProps {
  question: string;
  answer: boolean;
  contentFile: string;
  onAffirmativeClick: () => void;
  showButton?: boolean;
}

const contextContentTheme: Components = {
  h1: ({ ...props }) => <Text as="h3" fontSize="md" fontWeight="bold" {...props} />,
  p: ({ ...props }) => <Text mb={3} {...props} />,
  ul: ({ ...props }) => <Box as="ul" pl={4} {...props} />,
  ol: ({ ...props }) => <Box as="ol" pl={4} {...props} />,
  li: ({ ...props }) => <Box as="li" listStyleType="disc" {...props} />,
  table: ({ children, ...props }) => (
    <Box overflowX="auto" w="full" my={4}>
      <Table variant="simple" {...props}>
        {children}
      </Table>
    </Box>
  ),
  thead: ({ children, ...props }) => (
    <Thead bg="gray.50" {...props}>
      {children}
    </Thead>
  ),
  tbody: ({ children, ...props }) => <Tbody {...props}>{children}</Tbody>,
  tr: ({ children, ...props }) => <Tr {...props}>{children}</Tr>,
  th: ({ children, ...props }) => (
    <Th fontWeight="bold" {...props}>
      {children}
    </Th>
  ),
  td: ({ children, ...props }) => <Td {...props}>{children}</Td>,
  em: ({ children, ...props }) => (
    <Text as="em" fontStyle="italic" color="gray.600" {...props}>
      {children}
    </Text>
  ),
  strong: ({ children, ...props }) => (
    <Text as="strong" fontWeight="bold" {...props}>
      {children}
    </Text>
  )
};

const parseTable = (content: string) => {
  const lines = content.split("\n");
  const tableData = {
    headers: [] as string[],
    rows: [] as string[][]
  };

  let inTable = false;

  lines.forEach((line) => {
    if (line.startsWith("|")) {
      if (!inTable) {
        // First row is headers
        tableData.headers = line
          .split("|")
          .filter((cell) => cell.trim())
          .map((cell) => cell.trim());
        inTable = true;
      } else if (!line.includes("---")) {
        // Skip separator row and add data rows
        tableData.rows.push(
          line
            .split("|")
            .filter((cell) => cell.trim())
            .map((cell) => cell.trim())
        );
      }
    }
  });

  return tableData;
};

const Context = ({
  contentFile,
  question,
  answer,
  onAffirmativeClick,
  showButton = true
}: ContextProps) => {
  const [content, setContent] = useState("");
  const [tableData, setTableData] = useState<{ headers: string[]; rows: string[][] }>({ headers: [], rows: [] });

  useEffect(() => {
    fetch(contentFile)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
        setTableData(parseTable(text));
      });
  }, [contentFile]);

  const handleAffirmativeClick = () => {
    onAffirmativeClick();
  };

  if (content === "" || question === "") return <Text>No context to display</Text>;

  // Split content into parts: before table, table, and after table
  const parts = content.split(/\n\|/);
  const beforeTable = parts[0] || "";
  const afterTable = parts.length > 1 ? parts[parts.length - 1].split("\n").slice(1).join("\n") : "";

  return (
    <Box display="flex" flexDirection="column" gap={4} p={4} maxH={"50vh"} w="full">
      <Box maxHeight="full" overflowY="auto" mb={4} rounded={"md"} bg="blackAlpha.100" p={6} shadow="sm">
        {/* Content before table */}
        <ReactMarkdown components={contextContentTheme} skipHtml>
          {beforeTable}
        </ReactMarkdown>

        {/* Table */}
        {tableData.headers.length > 0 && (
          <Box overflowX="auto" w="full" my={6}>
            <Table variant="striped" colorScheme="blackAlpha" size="sm">
              <Thead>
                <Tr>
                  {tableData.headers.map((header, i) => (
                    <Th key={i} fontWeight="bold" fontSize="sm">
                      <ReactMarkdown components={contextContentTheme} skipHtml>
                        {header}
                      </ReactMarkdown>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {tableData.rows.map((row, i) => (
                  <Tr key={i}>
                    {row.map((cell, j) => (
                      <Td key={j}>
                        <ReactMarkdown components={contextContentTheme} skipHtml>
                          {cell}
                        </ReactMarkdown>
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}

        {/* Content after table */}
        {afterTable && (
          <ReactMarkdown components={contextContentTheme} skipHtml>
            {afterTable}
          </ReactMarkdown>
        )}
      </Box>
      {showButton && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            colorScheme={answer ? "brand" : "gray"}
            color={answer ? "brand" : "gray"}
            onClick={handleAffirmativeClick}
          >
            {answer ? "Understood." : "I Understand."}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Context;
