import { Question, QuestionType, Answer } from "@shared/models";

import { BasicInfoFormData } from "../components/DynamicQuestion/CustomQuestions/BasicInfo";
import { TableMultiSelectSavedOption } from "../components/TableMultiSelect/TableMultiSelect";

// Constants moved to a separate object
export const BASIC_INFO_DISPLAY_NAMES: Record<keyof BasicInfoFormData, string> = {
  establishmentName: "Establishment Name",
  licenseHolderFirstName: "License Holder First Name",
  licenseHolderLastName: "License Holder Last Name",
  address: "Address",
  email: "Email",
  phoneNumber: "Phone Number"
};

// Type guard functions
export const isBasicInfoAnswer = (answer: unknown): answer is BasicInfoFormData => {
  return typeof answer === "object" && answer !== null && "establishmentName" in answer;
};

export const isTableSelectAnswer = (answer: unknown): answer is TableMultiSelectSavedOption[] => {
  return Array.isArray(answer) && answer.every((item) => "xAxisOption" in item && "yAxisOption" in item);
};

// Core formatting function
export const formatAnswer = (answer?: Answer | null): string => {
  if (answer === undefined || answer === null || answer === "") {
    return "N/A";
  }

  if (typeof answer === "boolean" || answer === "true" || answer === "false") {
    return answer ? "Yes" : "No";
  }

  if (typeof answer === "string") {
    return answer;
  }

  if (Array.isArray(answer)) {
    return answer.join(", ");
  }

  return Object.entries(answer)
    .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(", ") : value}`)
    .join("\n");
};

// Structure creation function
export const createAnswerStructure = (question: Question, answer: Answer): string | BasicInfoFormData => {
  if (answer === undefined || answer === null || answer === "") return "";

  if (question.type === QuestionType.BasicInfo && isBasicInfoAnswer(answer)) {
    return formatBasicInfoAnswer(answer);
  }

  if (question.type === QuestionType.TableSelect && isTableSelectAnswer(answer)) {
    return formatTableSelectAnswer(answer);
  }

  return formatAnswer(answer);
};

// Specialized formatting functions
const formatBasicInfoAnswer = (answer: BasicInfoFormData): BasicInfoFormData => {
  return Object.keys(BASIC_INFO_DISPLAY_NAMES).reduce(
    (acc, key) => ({
      ...acc,
      [key]: answer[key as keyof BasicInfoFormData] || "-"
    }),
    {} as BasicInfoFormData
  );
};

const formatTableSelectAnswer = (answer: TableMultiSelectSavedOption[]): string => {
  const groupedByYAxis = answer.reduce(
    (acc, option) => {
      if (option.yAxisOption?.value) {
        if (!acc[option.yAxisOption.value]) {
          acc[option.yAxisOption.value] = [];
        }
        if (option.xAxisOption?.value) {
          acc[option.yAxisOption.value].push(option.xAxisOption.value);
        }
      }
      return acc;
    },
    {} as Record<string, string[]>
  );

  return Object.entries(groupedByYAxis)
    .sort()
    .map(([yAxis, xAxisValues]) => `${yAxis}: ${xAxisValues.sort().join(", ")}`)
    .join("\n");
};
