import React, { useEffect } from "react";

import { HStack, VStack, Box, Spinner } from "@chakra-ui/react";
import { Section, Page } from "@shared/models";
import { Outlet, useLocation } from "react-router-dom";

import SectionNavigation from "../../components/SectionNavigation";
import { useNavigation } from "../../hooks/useNavigation";
import { usePageStatus } from "../../hooks/usePageStatus";
import { useSopAnswers } from "../../hooks/useSopAnswers";
import { sopGuide } from "../../services/sop-builder-service";
import { useAppStore } from "../../store";

export interface PageStatus {
  [key: string]: {
    [key: string]: "not-started" | "started" | "completed";
  };
}

const SopBuilderPage: React.FC = () => {
  const { data: sopAnswers, isLoading } = useSopAnswers();
  const { pageStatus, getSectionState } = usePageStatus();
  const { updateSessionFromAnswers } = useAppStore();
  const { navigateTo } = useNavigation();
  const { currentUrlSlug, setCurrentUrlSlug } = useAppStore();
  const location = useLocation();

  useEffect(() => {
    if (sopAnswers && Object.keys(sopAnswers).length > 0) {
      updateSessionFromAnswers(sopAnswers);
    }
  }, [sopAnswers]);

  useEffect(() => {
    const path = location.pathname.split("/sop-builder/")[1];

    if (path) {
      const [sectionUrlSlug, pageUrlSlug] = path.split("/");
      if (sectionUrlSlug !== currentUrlSlug.sectionUrlSlug || pageUrlSlug !== currentUrlSlug.pageUrlSlug) {
        setCurrentUrlSlug({ sectionUrlSlug, pageUrlSlug: pageUrlSlug || "" });
      }
    }
  }, [location.pathname, currentUrlSlug, setCurrentUrlSlug]);

  const handlePageClick = (section: Section, page: Page) => {
    navigateTo(section.urlSlug, page.urlSlug);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  const heightMargin = 122; // TODO: This is a quick-fix to avoid extra scrollbars.
  // We should fix this properly at some point to make this less brittle and more resistant to future changes.
  return (
    <VStack
      w="full"
      h="full"
      maxH={`calc(100vh - ${heightMargin}px)`}
      alignItems="stretch"
      overflow="hidden"
      position="relative"
    >
      <HStack w="full" h="full" alignItems="flex-start" justifyContent="center" gap={6}>
        <SectionNavigation
          sections={sopGuide.sections}
          getSectionState={getSectionState}
          checkedItems={pageStatus}
          inProgressItems={pageStatus}
          handlePageClick={handlePageClick}
          currentUrlSlug={currentUrlSlug}
        />

        <HStack w="full" h="full" justifyContent="center" alignItems="flex-start">
          <VStack
            overflowY="auto"
            w="100%"
            h="full"
            maxH="container.xl"
            maxWidth="container.xl"
            alignItems="center"
            justifyContent="center"
          >
            <Outlet />
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default SopBuilderPage;
