import React from "react";

import { VStack, Text, ListItem, Checkbox, UnorderedList, Stack } from "@chakra-ui/react";

const Welcome: React.FC = () => {

  return (
    <VStack spacing={8} mt={8} width="100%" alignItems={"flex-start"}>
      <Text fontSize="xl" fontWeight="bold">
        Welcome to your SOP builder. Welcome to simpler.
      </Text>

      <VStack alignItems={"flex-start"} spacing={4}>
        <Text fontSize="md">
          As we get started, there are two main things you will need and one strong recommendation to get this done
          properly.
        </Text>
        <Text fontSize="md">
          The two things you need are your menu/what you are serving and what equipment you will have.
        </Text>
        <Text fontSize="md">
          The recommendation is your ServSafe certification. You will need to submit that as an attachment with the SOPS
          to the health department anyway, but knowing that beforehand will significantly help this process.
        </Text>
      </VStack>

      <VStack alignItems={"flex-start"} spacing={4} width="100%">
        <Text fontWeight="bold">What you will need:</Text>
        <Stack spacing={6} direction={["column", "column", "column", "row"]}>
          <Checkbox colorScheme="brand">Menu/Food Items</Checkbox>
          <Checkbox colorScheme="brand">Equipment List</Checkbox>
          <Checkbox colorScheme="brand">CompletedServSafe Certification (Recommended)</Checkbox>
        </Stack>

        <Text fontWeight="bold" mt={4}>
          What you need to know:
        </Text>
        <UnorderedList spacing={2} pl={4}>
          <ListItem>Your answers are automatically saved as you go.</ListItem>
          <ListItem>This takes about 2 hours from start to finish.</ListItem>
          <ListItem ml={6} style={{ fontStyle: "italic" }} color="gray.600" fontSize="sm">
            You DO NOT need to do it all at once. Come back as you need.
          </ListItem>
          <ListItem>Our process saves you 3 hours, on average.</ListItem>
          <ListItem>To help speed you along, we will offer prefilled answers where we can.</ListItem>
        </UnorderedList>
      </VStack>
    </VStack>
  );
};

export default Welcome;
