import { useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { processPdf } from "../services/api-service";

export const usePdfUpload = () => {
  const [extractedItems, setExtractedItems] = useState<string[]>([]);

  const processPdfMutation = useMutation({
    mutationFn: processPdf,
    onSuccess: (items: string[]) => {
      setExtractedItems((prev) => {
        const existingItems = new Set(prev);

        const newItems = items.filter((item) => !existingItems.has(item));

        return [...prev, ...newItems];
      });
    }
  });

  const uploadAndProcessPdf = async (file: File): Promise<void> => {
    try {
      await processPdfMutation.mutateAsync(file);
    } catch (error) {
      console.error("Error processing PDF:", error);
      throw error;
    }
  };

  const clearExtractedItems = () => {
    setExtractedItems([]);
  };

  return {
    uploadAndProcessPdf,
    extractedItems,
    clearExtractedItems,
    isUploading: processPdfMutation.isPending,
    isError: processPdfMutation.isError,
    error: processPdfMutation.error
  };
};
