import { useState } from "react";

import {
  HStack,
  Text,
  Icon,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
} from "@chakra-ui/react";
import { QuestionDependency, QuestionDependencyType } from "@shared/models";
import { FiInfo } from "react-icons/fi";

import { workflow } from "../../constants/workflow";
import { useNavigation } from "../../hooks/useNavigation";

interface DependencyInfoPopoverProps {
  dependencies: QuestionDependency[];
}

const DependencyInfoPopover: React.FC<DependencyInfoPopoverProps> = ({ dependencies }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { navigateTo } = useNavigation();

  return (
    <HStack spacing={2} position="relative">
      <Text as="div" fontSize="sm" color="gray.500" fontStyle="italic">
        This question depends on your answers from previous questions.
      </Text>
      <Popover
        isOpen={isPopoverOpen}
        onOpen={() => setIsPopoverOpen(true)}
        onClose={() => setIsPopoverOpen(false)}
        trigger="hover"
        placement="bottom"
        closeDelay={0}
      >
        <PopoverTrigger>
          <Box display="inline-block">
            <Icon
              as={FiInfo}
              fontSize="lg"
              color="brand.300"
              cursor="pointer"
              _hover={{ color: "brand.600" }}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          maxW="700px"
          w="full"
          bg="gray.700"
          color="white"
          border="none"
          borderRadius="md"
          boxShadow="lg"
          _focus={{ outline: 'none' }}
          onMouseEnter={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
        >
          <PopoverBody p={4}>
            <VStack alignItems="space-between" spacing={2} w="full">
              {dependencies
                .filter((dep) => dep.type === QuestionDependencyType.Required || dep.type === QuestionDependencyType.NotRequired)
                .map((dep) => {
                  const section = workflow.sections.find((section) =>
                    section.pages.find((page) => page.questions.find((q) => q.id === dep.questionId))
                  );
                  const page = section?.pages.find((page) => page.questions.find((q) => q.id === dep.questionId));
                  const dependentQuestion = page?.questions.find((q) => q.id === dep.questionId);

                  return (
                    <HStack key={dep.questionId} spacing={4} w="full" justifyContent="space-between">
                      <Text color="white">
                        {(dependentQuestion?.prompt || "Unknown question").length > 50
                          ? `${(dependentQuestion?.prompt || "Unknown question").slice(0, 45).trim()}...`
                          : dependentQuestion?.prompt || "Unknown question"}
                      </Text>
                      <Text
                        color="blue.300"
                        textDecoration="underline"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (section?.urlSlug && page?.urlSlug) {
                            navigateTo(section.urlSlug, page.urlSlug);
                          }
                        }}
                      >
                        Go to question
                      </Text>
                    </HStack>
                  );
                })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default DependencyInfoPopover;